import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-877e6dee"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "tree-label" }
const _hoisted_4 = ["href", "disabled", "innerHTML"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  class: "popover",
  ref: "popover",
  role: "tooltip"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "tree-children" }
const _hoisted_9 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tree-node", { opened: $props.node.open }])
  }, [
    _createElementVNode("span", {
      ref: "reference",
      class: _normalizeClass(["tree-link", { active: $props.node.value === $props.selected_node.value }]),
      onClick: _cache[1] || (_cache[1] = $event => ($setup.emit('node-click', $props.node))),
      disabled: $props.node.fetching,
      onMouseover: $setup.onMouseover,
      onMouseleave: $setup.onMouseleave
    }, [
      _createElementVNode("div", { innerHTML: $setup.icon }, null, 8 /* PROPS */, _hoisted_2),
      _createElementVNode("a", _hoisted_3, _toDisplayString($props.node.label), 1 /* TEXT */),
      _createCommentVNode(" Icon open File record in new tab "),
      ($props.node.is_leaf)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: $setup.open_file($props.node.value),
            disabled: $props.node.fetching,
            target: "_blank",
            class: "file-doc-link ml-2",
            innerHTML: _ctx.frappe.utils.icon('external-link', 'sm'),
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, null, 8 /* PROPS */, _hoisted_4))
        : _createCommentVNode("v-if", true)
    ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1),
    ($props.node.file_url && _ctx.frappe.utils.is_image_file($props.node.file_url))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _withDirectives(_createElementVNode("div", _hoisted_6, [
            _createElementVNode("img", {
              src: $props.node.file_url
            }, null, 8 /* PROPS */, _hoisted_7)
          ], 512 /* NEED_PATCH */), [
            [_vShow, $setup.isOpen]
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("ul", _hoisted_8, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.node.children, (n) => {
        return (_openBlock(), _createBlock($setup["TreeNode"], {
          key: n.value,
          node: n,
          selected_node: $props.selected_node,
          onNodeClick: (n) => $setup.emit('node-click', n),
          onLoadMore: (n) => $setup.emit('load-more', n)
        }, null, 8 /* PROPS */, ["node", "selected_node", "onNodeClick", "onLoadMore"]))
      }), 128 /* KEYED_FRAGMENT */)),
      ($props.node.has_more_children)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btn btn-xs btn-load-more",
            onClick: _cache[2] || (_cache[2] = $event => ($setup.emit('load-more', $props.node))),
            disabled: $props.node.children_loading
          }, _toDisplayString($props.node.children_loading ? _ctx.__("Loading...") : _ctx.__("Load more")), 9 /* TEXT, PROPS */, _hoisted_9))
        : _createCommentVNode("v-if", true)
    ], 512 /* NEED_PATCH */), [
      [_vShow, $props.node.open]
    ])
  ], 2 /* CLASS */))
}