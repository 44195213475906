import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d6847533"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-preview" }
const _hoisted_2 = { class: "file-icon" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "file-name" }
const _hoisted_7 = {
  key: 1,
  class: "file-name"
}
const _hoisted_8 = { class: "file-size" }
const _hoisted_9 = { class: "flex config-area" }
const _hoisted_10 = {
  key: 0,
  class: "frappe-checkbox"
}
const _hoisted_11 = ["checked"]
const _hoisted_12 = { class: "frappe-checkbox" }
const _hoisted_13 = ["checked"]
const _hoisted_14 = {
  key: 0,
  class: "file-error text-danger"
}
const _hoisted_15 = { class: "file-actions" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = ["innerHTML"]
const _hoisted_18 = { class: "file-action-buttons" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.is_image)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $setup.src,
            alt: $props.file.name
          }, null, 8 /* PROPS */, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "fallback",
            innerHTML: _ctx.frappe.utils.icon('file', 'md')
          }, null, 8 /* PROPS */, _hoisted_4))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        ($props.file.doc)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "flex",
              href: $props.file.doc.file_url,
              target: "_blank"
            }, [
              _createElementVNode("span", _hoisted_6, _toDisplayString($props.file.name), 1 /* TEXT */)
            ], 8 /* PROPS */, _hoisted_5))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.file.name), 1 /* TEXT */))
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_8, _toDisplayString($setup.file_size), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_9, [
        ($setup.is_optimizable)
          ? (_openBlock(), _createElementBlock("label", _hoisted_10, [
              _createElementVNode("input", {
                type: "checkbox",
                checked: $setup.optimize,
                onChange: _cache[0] || (_cache[0] = $event => ($setup.emit('toggle_optimize')))
              }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_11),
              _createTextVNode(_toDisplayString(_ctx.__("Optimize")), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true),
        _createElementVNode("label", _hoisted_12, [
          _createElementVNode("input", {
            type: "checkbox",
            checked: $props.file.private,
            onChange: _cache[1] || (_cache[1] = $event => ($setup.emit('toggle_private')))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13),
          _createTextVNode(_toDisplayString(_ctx.__("Private")), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", null, [
        ($props.file.error_message)
          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString($props.file.error_message), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _withDirectives(_createVNode($setup["ProgressRing"], {
        primary: "var(--primary-color)",
        secondary: "var(--gray-200)",
        radius: 24,
        progress: $setup.progress,
        stroke: 3
      }, null, 8 /* PROPS */, ["progress"]), [
        [_vShow, $props.file.uploading && !$setup.uploaded && !$props.file.failed]
      ]),
      ($setup.uploaded)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            innerHTML: _ctx.frappe.utils.icon('solid-success', 'lg')
          }, null, 8 /* PROPS */, _hoisted_16))
        : _createCommentVNode("v-if", true),
      ($props.file.failed)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            innerHTML: _ctx.frappe.utils.icon('solid-error', 'lg')
          }, null, 8 /* PROPS */, _hoisted_17))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_18, [
        ($setup.is_cropable)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-crop muted",
              onClick: _cache[2] || (_cache[2] = $event => ($setup.emit('toggle_image_cropper'))),
              innerHTML: _ctx.frappe.utils.icon('crop', 'md')
            }, null, 8 /* PROPS */, _hoisted_19))
          : _createCommentVNode("v-if", true),
        (!$setup.uploaded && !$props.file.uploading && !$props.file.failed)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn muted",
              onClick: _cache[3] || (_cache[3] = $event => ($setup.emit('remove'))),
              innerHTML: _ctx.frappe.utils.icon('delete', 'md')
            }, null, 8 /* PROPS */, _hoisted_20))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}